import firebase from "firebase/app";

export default async function sendNotification({ title, subtitle, message, token, device }) {
    if (!process.env.REACT_APP_RYDE_API) {
        console.error('process.env.REACT_APP_RYDE_API env var is undefined');
        return;
    }

    if (token) {
        let url;
        let data;

        if (device === 'android') {
            console.log('Preparing for android push');
            url = process.env.REACT_APP_RYDE_API + '/notify/android';
            data = {
                "message": {
                    "token": token,
                    "notification": {
                        title,
                        body: message,
                    },
                    "android": {
                        priority: "high",
                        notification: {
                        },
                    },
                    "data": {
                        name: "RIDE",
                        riderId: "abcd12345",
                    },
                }
            };
        } else {
            console.log('Preparing for iphone push');
            url = process.env.REACT_APP_RYDE_API + '/notify/ios';
            data = {
                token,
                device,
                aps: {
                    category: 'RIDE',
                    alert: {
                        title,
                        subtitle,
                        body: message,
                    },
                    sound: 'default',
                },
                body: {
                    name: 'RIDE',
                }, // equivalent of "data": {} in android
                experienceId: '@riamuapp/riamu',
                scopeKey: '@riamuapp/riamu',
            };
        }

        const currentUser = firebase.auth().currentUser;
        const idToken = await currentUser.getIdToken(true);

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify(data),
        };

        console.log(`HTTP POST ${url}`, data);

        fetch(url, config)
            .then(function (response) {
                console.log('response', response);
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }
}
